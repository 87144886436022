@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100&family=Noto+Sans+Arabic:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,500;0,600;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  // font-family: "IBM Plex Sans Arabic", sans-serif;
  // font-family: "Noto Sans Arabic", sans-serif;
  // font-family: "Open Sans", sans-serif;
  font-family: "Tajawal" !important;
}

li {
  list-style: none;
}
a {
  cursor: pointer;
  text-decoration: none !important  ;
  color: black;
}
