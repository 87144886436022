nav {
  z-index: 5;
  box-shadow: 0 2px 15px #ccc;
  a {
    color: $black;
  }
  .partial {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(244, 244, 244);
    text-align: right;
    padding: 30px;
    animation: rightTOleft 0.3s;
    li {
      padding: 14px 0;
      border-bottom: 1px solid #c3c3c3;
      a {
        font-size: 20px;
        color: #333 !important;
        &:hover {
          // color: white !important;
          font-weight: bold;
        }
      }
    }

    span {
      color: #333 !important;
      cursor: pointer;
    }
  }
  .burger {
    cursor: pointer;
  }
}
@keyframes rightTOleft {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}
