.contact {
  padding: $sectionPadd;
  background-color: #333;
  color: white;
  .container {
    .row {
      .text {
        span {
          color: $primary-color;
        }
        p {
          padding: 20px;
          text-align: right;
          padding: 0;
        }
      }
      form {
        .hide-box {
          position: relative;
          padding: 0;
          padding: 11px 8px;
        }
        textarea,
        input {
          background-color: rgba(255, 255, 255, 0.239);
          width: 100%;
          padding: 10px;
          outline: none;
          border: none;
          border-bottom: 1px solid white;
          color: white;
          &::placeholder {
            color: white;
          }
        }
        button {
        }
      }
    }
  }
}
