.heading {
  text-align: right;
  margin-bottom: 50px;
  .heading-desc {
    font-weight: 900;
    color: $gray;
    font-size: 20px;
    margin: 0;
  }
  .heading-title {
    font-size: 45px;
    @media (max-width: 500px) {
      font-size: 35px;
    }
    font-weight: 900;
    margin: 0;
  }
}

.heading-1 {
  span {
    color: $primary-color;
    font-size: 20px;
  }
  h4 {
    font-weight: bold;
    position: relative;
    width: fit-content;
    font-size: 30px;
    &::before {
      content: "";
      width: 60px;
      height: 2px;
      position: absolute;
      right: -70px;
      top: 50%;
      transform: translateY(-50%);
      background-color: $primary-color;
    }
  }
}
.heading-2 {
  margin-bottom: 50px;
  span {
    color: $primary-color;
    font-size: 22px;
  }
  h4 {
    font-size: 30px;
    font-weight: bold;
    position: relative;
    &::before {
      content: "";
      width: 60px;
      height: 3px;
      position: absolute;
      left: 50%;
      top: 44px;
      transform: translateX(-50%);
      background-color: $primary-color;
    }
  }
}

.button {
  width: 200px;
  background-color: $primary-color;
  border: none !important;
  color: white;
  padding: 10px 20px;
}
