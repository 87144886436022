$font-xl: 35px;
$font-lg: 25px;
$font-md: 20px;
$font-sm: 16px;
$gray: #777;
$black: #333;
$background: #f2f2f2;
$primary-color: #c0b596;
$raduis: 15px;
$sectionPadd: 130px 0;
$sectionBack: #f5f5f5;
