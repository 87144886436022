@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100&family=Noto+Sans+Arabic:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,500;0,600;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Tajawal" !important;
}

li {
  list-style: none;
}

a {
  cursor: pointer;
  text-decoration: none !important;
  color: black;
}

.heading {
  text-align: right;
  margin-bottom: 50px;
}
.heading .heading-desc {
  font-weight: 900;
  color: #777;
  font-size: 20px;
  margin: 0;
}
.heading .heading-title {
  font-size: 45px;
  font-weight: 900;
  margin: 0;
}
@media (max-width: 500px) {
  .heading .heading-title {
    font-size: 35px;
  }
}

.heading-1 span {
  color: #c0b596;
  font-size: 20px;
}
.heading-1 h4 {
  font-weight: bold;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 30px;
}
.heading-1 h4::before {
  content: "";
  width: 60px;
  height: 2px;
  position: absolute;
  right: -70px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #c0b596;
}

.heading-2 {
  margin-bottom: 50px;
}
.heading-2 span {
  color: #c0b596;
  font-size: 22px;
}
.heading-2 h4 {
  font-size: 30px;
  font-weight: bold;
  position: relative;
}
.heading-2 h4::before {
  content: "";
  width: 60px;
  height: 3px;
  position: absolute;
  left: 50%;
  top: 44px;
  transform: translateX(-50%);
  background-color: #c0b596;
}

.button {
  width: 200px;
  background-color: #c0b596;
  border: none !important;
  color: white;
  padding: 10px 20px;
}

.landing {
  background-image: url("../../public/images/landing.avif");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
}
.landing::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.436);
  z-index: 1;
}
.landing .title {
  position: absolute;
  color: white;
  z-index: 1;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  height: 200px;
  min-width: 300px;
  max-width: 500px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
.landing .title h1 {
  text-align: start;
}
.landing .title button {
  position: relative;
}
.landing .title button::before {
  content: "";
  position: absolute;
  background-color: white;
  top: 50%;
  right: -30%;
  height: 2px;
  width: 40%;
  transform: translateY(-50%);
}

.how-it-work {
  padding: 40px;
  background-color: #333;
}
.how-it-work .hide-box .box {
  background-color: #333;
  border: 1px solid #c0b596;
}
.how-it-work .hide-box .box .image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-left: 1px solid #c0b596;
}
.how-it-work .hide-box .box .image img {
  width: 64px !important;
  height: 64px;
}
.how-it-work .hide-box .box .text span {
  text-align: right !important;
  color: #c0b596 !important;
  font-weight: 400;
}
.how-it-work .hide-box .box .text h4 {
  text-align: right !important;
}

.why-you-need-us {
  padding: 130px 0;
  display: flex;
  flex-direction: row-reverse;
}
.why-you-need-us .row {
  flex-direction: row-reverse;
}
.why-you-need-us .image {
  position: relative;
  margin: 0;
  padding: 0;
  box-shadow: -20px 20px 0px -10px #c0b596;
}
.why-you-need-us .expert {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.why-you-need-us .expert p {
  color: #777;
  line-height: 1.6;
  text-align: right;
  padding: 10px;
}
.why-you-need-us .expert button {
  text-align: center;
  display: block;
}

.how-can-we-help-you {
  padding: 100px 0;
  background-image: url("../../public/images/writing.jpg");
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
}
.how-can-we-help-you::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.508);
  z-index: 1;
}
.how-can-we-help-you .heading-2 {
  position: relative;
  z-index: 1;
}
.how-can-we-help-you .heading-2 h4 {
  color: white;
}
.how-can-we-help-you .hide-box {
  position: relative;
  z-index: 1;
}
.how-can-we-help-you .hide-box .box {
  background-color: rgba(255, 255, 255, 0.233);
}
.how-can-we-help-you .hide-box .box .image {
  border-left: 1px solid #c0b596;
  padding: 0 14px;
}
.how-can-we-help-you .hide-box .box .text {
  color: white;
  height: 70px;
}
.how-can-we-help-you .hide-box .box .text .title {
  font-weight: bold;
  position: relative;
  margin-bottom: 10px;
}
.how-can-we-help-you .hide-box .box .text .title::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -7px;
  background-color: #c0b596;
  width: 30px;
  height: 2px;
}
.how-can-we-help-you .hide-box .box .text .desc {
  margin: 0;
}

.testimonials {
  padding: 60px 0 0;
}
.testimonials .quotes .quote img {
  width: 34px;
  text-align: right;
  display: block;
}
.testimonials .quotes .quote p {
  text-align: right;
  color: #777;
  line-height: 1.6;
}
nav {
  z-index: 5;
  box-shadow: 0 2px 15px #ccc;
}
nav a {
  color: #333;
}
nav .partial {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(244, 244, 244);
  text-align: right;
  padding: 30px;
  animation: rightTOleft 0.3s;
}
nav .partial li {
  padding: 14px 0;
  border-bottom: 1px solid #c3c3c3;
}
nav .partial li a {
  font-size: 20px;
  color: #333 !important;
}
nav .partial li a:hover {
  font-weight: bold;
}
nav .partial span {
  color: #333 !important;
  cursor: pointer;
}
nav .burger {
  cursor: pointer;
}

@keyframes rightTOleft {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}
body {
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1170px;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

ul {
  list-style: none;
}

.footer {
  background-color: rgba(0, 0, 0, 0.973);
  padding: 40px 0;
}

.footerCol {
  width: 25%;
  padding: 0 15px;
}

.footerCol h4 {
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}

.footerCol h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #E6D8BB;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footerCol ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footerCol ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}

.footerCol ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}

.footerCol .socialLinks a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}

.footerCol .socialLinks a:hover {
  color: #24262b;
  background-color: #ffffff;
}

.iframeMap {
  width: 10rem;
}

.anchorTech {
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 25px;
}

.anchorTech:hover {
  color: #bbbbbb;
}

@media (max-width: 767px) {
  .footerCol {
    width: 50%;
    margin-bottom: 30px;
  }
}
@media (max-width: 574px) {
  .footerCol {
    width: 50%;
  }
}
.contact {
  padding: 130px 0;
  background-color: #333;
  color: white;
}
.contact .container .row .text span {
  color: #c0b596;
}
.contact .container .row .text p {
  padding: 20px;
  text-align: right;
  padding: 0;
}
.contact .container .row form .hide-box {
  position: relative;
  padding: 0;
  padding: 11px 8px;
}
.contact .container .row form textarea,
.contact .container .row form input {
  background-color: rgba(255, 255, 255, 0.239);
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: 1px solid white;
  color: white;
}
.contact .container .row form textarea::-moz-placeholder, .contact .container .row form input::-moz-placeholder {
  color: white;
}
.contact .container .row form textarea::placeholder,
.contact .container .row form input::placeholder {
  color: white;
}/*# sourceMappingURL=main.css.map */