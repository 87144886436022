.landing {
  background-image: url("../../public/images/landing.avif");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.436);
    z-index: 1;
  }
  .title {
    position: absolute;
    color: white;
    z-index: 1;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    height: 200px;
    min-width: 300px;
    max-width: 500px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    span {
    }
    h1 {
      text-align: start;
    }
    button {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background-color: white;
        top: 50%;
        right: -30%;
        height: 2px;
        width: 40%;
        transform: translateY(-50%);
      }
    }
  }
}

// How It Work

.how-it-work {
  padding: 40px;
  background-color: $black;
  .hide-box {
    .box {
      background-color: $black;
      border: 1px solid $primary-color;
      .image {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-left: 1px solid $primary-color;
        img {
          width: 64px !important;
          height: 64px;
        }
      }
      .text {
        span {
          text-align: right !important;
          color: $primary-color !important;
          font-weight: 400;
        }
        h4 {
          text-align: right !important;
        }
      }
    }
  }
}

// Why You Need Us

.why-you-need-us {
  padding: $sectionPadd;
  display: flex;
  flex-direction: row-reverse;
  .row {
    flex-direction: row-reverse;
  }
  .image {
    position: relative;
    margin: 0;
    padding: 0;
    box-shadow: -20px 20px 0px -10px $primary-color;
    img {
      &::before {
      }
    }
  }
  .expert {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      color: $gray;
      line-height: 1.6;
      text-align: right;
      padding: 10px;
    }
    button {
      text-align: center;
      display: block;
    }
  }
}

// How Can We Help You

.how-can-we-help-you {
  padding: 100px 0;
  background-image: url("../../public/images/writing.jpg");
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.508);
    z-index: 1;
  }
  .heading-2 {
    position: relative;
    z-index: 1;
    h4 {
      color: white;
    }
  }
  .hide-box {
    position: relative;
    z-index: 1;
    .box {
      background-color: rgba(255, 255, 255, 0.233);
      .image {
        border-left: 1px solid $primary-color;
        padding: 0 14px;
        img {
        }
      }
      .text {
        color: white;
        height: 70px;
        .title {
          font-weight: bold;
          position: relative;
          margin-bottom: 10px;
          &::before {
            content: "";
            position: absolute;
            right: 0;
            bottom: -7px;
            background-color: $primary-color;
            width: 30px;
            height: 2px;
          }
        }
        .desc {
          margin: 0;
        }
      }
    }
  }
}

// Testimonials

.testimonials {
  padding: 60px 0 0;
  .quotes {
    .quote {
      img {
        width: 34px;
        text-align: right;
        display: block;
      }
      p {
        text-align: right;
        color: $gray;
        line-height: 1.6;
      }
      .lawer {
        img {
        }
        h4 {
        }
        span {
        }
      }
      .image {
        img {
        }
      }
    }
  }
}
